.main-hero {
  position: relative;
  min-height: 600px;
  display: flex;
  align-items: center;
  padding-left: 9.722%;

  .gradient {
    position: absolute;
    background-color: inherit;
    background-size: auto 100%;
    background-position: right;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    @media (max-width: 560px) {
      position: inherit;
      height:325px;
      margin-top:0;    
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: inherit;
      background: linear-gradient(90deg, rgba(255,255,255,1) 23%, rgba(255,255,255,0) 40%);

      @media (max-width: 560px) {
        display: none;
      }
    }
  }

  .text-hero {
    position: relative;
    z-index: 10;

    @media (max-width: 560px) {
      padding: 35.5px;
    }

    h1 {
      max-width: 547px;
      text-align: left;
      font-family: 'Cormorant Garamond';
      font-weight: 500;
      font-size: 45px;
      line-height: 55px;
      letter-spacing: 0px;
      color: #1F1F1F;

      @media (max-width: 560px) {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
      }
    }
  
    p {
      max-width: 507px;
      text-align: left;
      font-family: Inter;
      font-weight: 300;
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 0px;
      color: #1F1F1F;
      margin-bottom: 39px;

      @media (max-width: 560px) {
        margin-bottom:0;
      }
    }
  }

  @media (max-width: 620px) {
    padding: 20px;

    h1 {
      font-size: 30px;
      line-height: 1.2;
    }
  }

  @media (max-width: 560px) {
    padding: 0;
    flex-direction: column-reverse;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: inherit;
      background: linear-gradient(180deg, white 50%, rgba(255, 255, 255, 0) 80%);
    }

    a {
      width: 100%;
    }
  }
}